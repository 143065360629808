import React, { Component } from "react"
import { graphql } from "gatsby"
import Highlighter from "react-highlight-words";

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Loading from '../../components/oval.svg';

class BooksPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      bookMetadata: {},
    };

    this.handleSearchInput = this.handleSearchInput.bind(this);
  }

  componentDidMount() {
    // fetch metadata from google
    const { data } = this.props;
    const bookList = data.allFile.edges.filter(({node}) => node.absolutePath.includes('books'));
    bookList.forEach(book => {
      const bookName = book.node.relativePath.split('.');
      fetch(`https://www.googleapis.com/books/v1/volumes?q=${bookName[0]}&maxResults=1&key=AIzaSyA_eJHgZxtL0xxTb5bXikzP7yc2vI8ppQ8`)
        .then((resp) => resp.json())
        .then((data) => {
          this.setState({
            bookMetadata: {
              ...this.state.bookMetadata,
              [bookName[0]]: data.items[0].volumeInfo,
            }
          });
        });
    });
  }

  handleSearchInput(event) {
    this.setState({ searchTerm: event.target.value });
  }

  renderBookList(bookListItems) {
    if (bookListItems.length === 0) return <p>No results found.</p>;
    return (
      <ul className="book-list">
        {bookListItems}
      </ul>
    );
  }

  render() {
    const { data } = this.props;
    const { searchTerm } = this.state;
    const bookList = data.allFile.edges.filter(({node}) => node.absolutePath.includes('books'));
    const filteredBookList = bookList.filter(({node}) => node.relativePath.toLowerCase().includes(searchTerm.toLowerCase()));
    filteredBookList.sort((a, b) => a.node.relativePath.localeCompare(b.node.relativePath));
    const bookListItems = filteredBookList.map(book => {
      const bookName = book.node.relativePath.split('.');
      const imgSrc = (this.state.bookMetadata[bookName[0]] && this.state.bookMetadata[bookName[0]].imageLinks && this.state.bookMetadata[bookName[0]].imageLinks.smallThumbnail) || Loading;
      return (
        <li key={bookName[0]} className="book-list-item">
          <a href={book.node.publicURL} className="book-list-item-link">
          <img src={imgSrc} alt={bookName[0]} width="128" height="195" />
          <Highlighter
            highlightClassName="highlightClass"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={bookName[0]}
          />
          <span>
          {' '} [{book.node.extension}] ({book.node.prettySize})
          </span>
          </a>
        </li>
      );
    });


    return (
      <Layout>
        <SEO title="Books" />
        <h1>Books</h1>
        <div className="search-books-container">
          <input value={searchTerm} onChange={this.handleSearchInput} autoFocus type="text" placeholder="Search for a book" className="search-books" />
          <div className="search-icon"><i className="fa fa-search"></i></div>
        </div>
        {this.renderBookList(bookListItems)}
      </Layout>
    );
  }
}

export default BooksPage

export const query = graphql`
  {
    allFile {
      edges {
        node {
          prettySize
          relativePath
          name
          extension
          publicURL
          modifiedTime(fromNow: true)
          absolutePath
        }
      }
    }
  }
`;
